import axios from '@/api/axios'

const state = {}

const mutations = {}

const actions = {
    async updateHomePageText(context, data) {
        return axios.post('pagetext/homepage/update', data)
            .then(res => {
                if(res.data.success) {
                    return true;
                }
            }).catch(err => {
                throw err;
            })
    },
    async updateProductsPageText(context, data) {
        return axios.post('pagetext/productspage/update', data)
        .then(res => {
                if(res.data.success) {
                    return true;
                }
            }).catch(err => {
                throw err;
            })
    },
    async updateCheckoutPageText(context, data) {
        return axios.post('pagetext/checkoutpage/update', data)
            .then(res => {
                if(res.data.success) {
                    return true;
                }
            }).catch(err => {
                throw err;
            })
    },
    async updateThankYouPageText(context, data) {
        return axios.post('pagetext/thankyoupage/update', data)
            .then(res => {
                if(res.data.success) {
                    return true;
                }
            }).catch(err => {
                throw err;
            })
    },
}

const getters = {}

export default {
    state,
    mutations,
    actions,
    getters,
};