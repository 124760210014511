import axios from '@/api/axios'

const state = {
  media: [],
  pagination: {},
}

const mutations = {
  SET_MEDIA(state, media) {
      state.media = media;
  },
  SET_PAGINATION(state, pagination) {
      state.pagination = pagination;
  }
}

const actions = {
    async fetchMedia(context, { page = 1, perPage = 5, search = '' }) {
        return axios.get(`media/list?page=${page}&count=${perPage}&search=${search}`)
        .then(res => {
            context.commit('SET_MEDIA', res.data.data)
            context.commit('SET_PAGINATION', {
                current_page: res.data.meta.current_page,
                last_page: res.data.meta.last_page,
                per_page: res.data.meta.per_page,
                from: res.data.meta.from,
                to: res.data.meta.to,
                total: res.data.meta.total,
            });
            return true;
        }).catch(err => {
            throw err;
        })
    },
    async storeMedia({commit, dispatch, state}, data) {
        return axios.post(`media/save`, data)
        .then((res) => {
            if(res.data.success) {
                return true;
            }
        })
        .catch(error => {
            throw error
        })
    },
    async updateMedia({commit, dispatch, state}, {uuid, data}) {
        return axios.put(`media/${uuid}/update`, data)
        .then((res) => {
            if(res.data.success) {
                return true;
            }
        })
        .catch(error => {
            throw error
        })
    },
    async deleteMedia({commit, dispatch, state}, uuid) {
        return axios.delete(`media/${uuid}/delete`)
            .then((res) => {
                if(res.data.success) {
                    return true;
                }
            })
            .catch(error => {
                throw error
            })
    },
};

const getters = {
  getMedia(state) {
    return state.media;
  },
  getMediaPagination(state) {
    return state.pagination;
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
};
