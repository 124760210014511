import axios from '@/api/axios'

const state = {
    config: [],
}

const mutations = {
    SET_CONFIG(state, data) {
        state.config = data
    }
}

const actions = {
    async fetchConfig(context) {
        return axios.get('config/all')
            .then(res => {
                context.commit('SET_CONFIG', res.data.data)
                return true;
            }).catch(err => {
                throw err;
            })
    },
    async updateHeadscripts(context, data) {
        return axios.post('settings/headscripts', data)
            .then(res => {
                if(res.data.success) {
                    return true;
                }
            }).catch(err => {
                throw err;
            })
    },
    async updateSocialMedia(context, data) {
        return axios.post('settings/socialmedia', data)
        .then(res => {
                if(res.data.success) {
                    return true;
                }
            }).catch(err => {
                throw err;
            })
    },
    async updateDeliveryCost(context, data) {
        return axios.post('settings/pagetext', data)
            .then(res => {
                if(res.data.success) {
                    return true;
                }
            }).catch(err => {
                throw err;
            })
    },
}

const getters = {
    getConfig(state) {
       return state.config;
    }
}

export default {
    state,
    mutations,
    actions,
    getters,
};